.sendbird-experimental__rem__units .sendbird-label--h-1 {
  font-size: 1.25rem;
}
.sendbird-experimental__rem__units .sendbird-label--h-2 {
  font-size: 1.125rem;
}
.sendbird-experimental__rem__units .sendbird-label--subtitle-1 {
  font-size: 1rem;
}
.sendbird-experimental__rem__units .sendbird-label--subtitle-2 {
  font-size: 0.875rem;
}
.sendbird-experimental__rem__units .sendbird-label--body-1 {
  font-size: 0.875rem;
}
.sendbird-experimental__rem__units .sendbird-label--body-2 {
  font-size: 0.75rem;
}
.sendbird-experimental__rem__units .sendbird-label--button-1 {
  font-size: 0.875rem;
}
.sendbird-experimental__rem__units .sendbird-label--button-2 {
  font-size: 0.875rem;
}
.sendbird-experimental__rem__units .sendbird-label--caption-1 {
  font-size: 0.875rem;
}
.sendbird-experimental__rem__units .sendbird-label--caption-2 {
  font-size: 0.75rem;
}
.sendbird-experimental__rem__units .sendbird-label--caption-3 {
  font-size: 0.75rem;
}
.sendbird-experimental__rem__units .sendbird-message-search-pannel .sendbird-message-search-pannel__input__container__input-area {
  font-size: 0.875rem;
}
.sendbird-experimental__rem__units .sendbird-checkbox {
  font-size: 1.375rem;
}
.sendbird-experimental__rem__units .sendbird-mention-user-label {
  font-size: 0.875rem;
}
.sendbird-experimental__rem__units .sendbird-mention-user-label.purple {
  font-size: 1.125rem;
}
.sendbird-experimental__rem__units .sendbird-message-input .sendbird-message-input--textarea,
.sendbird-experimental__rem__units .sendbird-message-input .sendbird-message-input--placeholder {
  font-size: 0.875rem;
}
.sendbird-experimental__rem__units .sendbird-input .sendbird-input__input,
.sendbird-experimental__rem__units .sendbird-input .sendbird-input__placeholder {
  font-size: 0.875rem;
}
.sendbird-experimental__rem__units .sendbird-tooltip__text {
  font-size: 0.75rem;
}
.sendbird-experimental__rem__units .sendbird-quote-message .sendbird-quote-message__replied-to .sendbird-quote-message__replied-to__text {
  font-size: 0.75rem;
}
.sendbird-experimental__rem__units .sendbird-quote-message .sendbird-quote-message__replied-message .sendbird-quote-message__replied-message__text-message {
  font-size: 0.75rem;
}
.sendbird-experimental__rem__units .sendbird-quote-message .sendbird-quote-message__replied-message__file-message {
  font-size: 0.75rem;
}