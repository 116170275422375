@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,600,700&display=swap');

.sb-message-template__border {
  position: relative;
}

.sb-message-template__border::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  box-shadow: inset 0 0 0 var(--border-width) var(--border-color);
  border-radius: var(--border-radius);
}

.sb-message-template__action {
  cursor: pointer;
}

.sb-message-template__parent {
  font-family: 'Roboto';
}

.sendbird-theme--light .sb-message-template__box {
}

.sendbird-theme--dark .sb-message-template__box {
}

.sendbird-theme--light .sb-message-template__text {
}

.sendbird-theme--dark .sb-message-template__text {
}

.sb-message-template__text {
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
}

.sendbird-theme--light .sb-message-template__text-button {
}

.sendbird-theme--dark .sb-message-template__text-button {
}

.sb-message-template__text-button {
  padding: 10px 20px;
  border-radius: 6px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  /* opacity: 0.8; */
}

.sb-message-template__text-button:hover {
  opacity: 1;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.sb-message-template__text-button,
.sb-message-template__image-button {
  cursor: pointer;
  border-style: none;
}

.sb-message-template__parent {
  border-radius: unset;
  background-color: transparent;
  overflow: hidden;
}

.sb-message-template__parent * {
  box-sizing: border-box;
}

.sb-message-template__carousel {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  touch-action: pan-y;
}

.sb-message-template__parent.sb-message-template__parent_for_carousel {
  overflow: visible;
}