.sendbird-parent-message-info-item {
  margin-top: 8px;
  position: relative;
}

.sendbird-parent-message-info-item__text-message,
.sendbird-parent-message-info-item__og-field {
  display: inline-block;
  padding-right: 4px;
  white-space: pre-line;
  word-break: break-all;
}

.sendbird-parent-message-info-item__og-field {
  display: inline-flex;
  flex-direction: column;
  margin-top: 4px;
}

.sendbird-parent-message-info-item__og-field__content {
  display: inline-flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px 12px;
}

.sendbird-parent-message-info-item__file-message {
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  gap: 8px;
}

.sendbird-parent-message-info-item__file-message__file-name {
  display: inline-block;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sendbird-parent-message-info-item__thumbnail-message {
  display: block;
  height: 148px;
  position: relative;
  width: 200px;
}

.sendbird-parent-message-info-item__multiple-files-message-wrapper {
  padding: 0;
}
.sendbird-theme--light .sendbird-parent-message-info-item__multiple-files-message-wrapper {
  background-color: transparent;
}
.sendbird-theme--dark .sendbird-parent-message-info-item__multiple-files-message-wrapper {
  background-color: transparent;
}

.sendbird-parent-message-info-item__thumbnail-message__thumbnail {
  border-radius: 16px;
  position: absolute;
}

.sendbird-parent-message-info-item__thumbnail-message__placeholder {
  align-items: center;
  display: flex;
  height: 148px;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.sendbird-parent-message-info-item__thumbnail-message__placeholder__icon {
  align-items: center;
  background-color: var(--sendbird-light-background-50);
  border-radius: 50%;
  display: flex;
  height: 56px;
  justify-content: center;
  width: 56px;
}

.sendbird-parent-message-info-item__thumbnail-message__image-cover {
  background-color: var(--sendbird-light-overlay-01);
  border-radius: 16px;
  display: none;
  height: 148px;
  position: absolute;
  top: 0;
  width: 100%;
}

.sendbird-parent-message-info-item__thumbnail-message__video {
  border-radius: 16px;
  height: 148px;
  position: absolute;
  width: 100%;
}

.sendbird-parent-message-info-item__thumbnail-message__icon-wrapper {
  align-items: center;
  display: flex;
  height: 148px;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.sendbird-parent-message-info-item__thumbnail-message__icon-wrapper__icon {
  align-items: center;
  background-color: var(--sendbird-light-ondark-01);
  border-radius: 50%;
  display: inline-flex;
  height: 56px;
  justify-content: center;
  width: 56px;
}

.sendbird-parent-message-info-item__thumbnail-message:hover {
  cursor: pointer;
}
.sendbird-parent-message-info-item__thumbnail-message:hover .sendbird-parent-message-info-item__thumbnail-message__image-cover {
  display: inline-flex;
}

.sendbird-parent-message-info-item__voice-message__item {
  height: 50px;
  min-width: 136px;
}