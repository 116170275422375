.typing-dots-container {
  align-items: center;
  border-radius: 16px;
  display: flex;
  gap: 6px;
  justify-content: center;
  padding: 16px 12px;
}
.sendbird-theme--light .typing-dots-container {
  background-color: var(--sendbird-light-background-100);
}
.sendbird-theme--dark .typing-dots-container {
  background-color: var(--sendbird-dark-background-400);
}
.typing-dots-container span {
  animation: blink 1.4s infinite;
  animation-fill-mode: both;
  border-radius: 50%;
  height: 8px;
  width: 8px;
}
.sendbird-theme--light .typing-dots-container span {
  background-color: var(--sendbird-light-background-700);
}
.sendbird-theme--dark .typing-dots-container span {
  background-color: var(--sendbird-dark-background-50);
}
.typing-dots-container span:nth-child(1) {
  animation-delay: 0.4s;
}
.typing-dots-container span:nth-child(2) {
  animation-delay: 0.6s;
}
.typing-dots-container span:nth-child(3) {
  animation-delay: 0.8s;
}

@keyframes blink {
  0% {
    opacity: 0.12;
    transform: scale(1);
  }
  21.43% {
    opacity: 0.38;
    transform: scale(1.2);
  }
  42.86% {
    opacity: 0.12;
    transform: scale(1);
  }
  100% {
    opacity: 0.12;
    transform: scale(1);
  }
}