.sb_mobile {
  height: 100%;
}

.sb_mobile__panelwrap {
  height: 100%;
}

.sb-show-main {
  padding: 0 !important;
}

.sb_mobile__panelwrap .sendbird-thread {
  width: 100%;
  height: 100%;
}
.sb_mobile__panelwrap .sendbird-thread .sendbird-thread-ui {
  max-width: 100%;
}
.sb_mobile__panelwrap .sendbird-thread .sendbird-thread-ui .sendbird-thread-ui__header {
  width: 100%;
}