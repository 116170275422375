.sendbird-openchannel__mobile-menu-hyperlink {
  display: block;
  width: 100%;
  text-decoration: none;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-stretch: normal;
  font-style: normal;
  line-height: inherit;
  letter-spacing: inherit;
  font-family: inherit;
}