.sendbird-muted-avatar {
  border-radius: 50%;
  display: inline-block;
}
.sendbird-muted-avatar .sendbird-muted-avatar__icon {
  position: relative;
}
.sendbird-muted-avatar .sendbird-muted-avatar__icon .sendbird-icon {
  position: absolute;
  top: 4px;
  left: 4px;
}
.sendbird-muted-avatar .sendbird-muted-avatar__bg {
  opacity: 0.5;
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 0;
}
.sendbird-theme--light .sendbird-muted-avatar .sendbird-muted-avatar__bg {
  background-color: var(--sendbird-light-primary-400);
}
.sendbird-theme--dark .sendbird-muted-avatar .sendbird-muted-avatar__bg {
  background-color: var(--sendbird-dark-primary-300);
}