/**
 * We operate the CSS files for Channel&GroupChannel modules in the GroupChannel.
 * So keep in mind that you should consider both components when you make changes in this file.
 */
.sendbird-voice-message-input-wrapper .sendbird-voice-message-input__indicator {
  height: 40px;
}

.sendbird-voice-message-input-wrapper-alert__body {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  height: 74px;
}

.sendbird-voice-message-input-wrapper-alert__body__ok-button {
  width: 74px;
}