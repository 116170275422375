.sendbird-theme--light .sendbird-color--onbackground-1 {
  color: var(--sendbird-light-onlight-01);
}
.sendbird-theme--dark .sendbird-color--onbackground-1 {
  color: var(--sendbird-dark-ondark-01);
}
.sendbird-theme--light .sendbird-color--onbackground-1--background-color {
  background-color: var(--sendbird-light-onlight-01);
}
.sendbird-theme--dark .sendbird-color--onbackground-1--background-color {
  background-color: var(--sendbird-dark-ondark-01);
}

.sendbird-theme--light .sendbird-color--onbackground-2 {
  color: var(--sendbird-light-onlight-02);
}
.sendbird-theme--dark .sendbird-color--onbackground-2 {
  color: var(--sendbird-dark-ondark-02);
}
.sendbird-theme--light .sendbird-color--onbackground-2--background-color {
  background-color: var(--sendbird-light-onlight-02);
}
.sendbird-theme--dark .sendbird-color--onbackground-2--background-color {
  background-color: var(--sendbird-dark-ondark-02);
}

.sendbird-theme--light .sendbird-color--onbackground-3 {
  color: var(--sendbird-light-onlight-03);
}
.sendbird-theme--dark .sendbird-color--onbackground-3 {
  color: var(--sendbird-dark-ondark-03);
}
.sendbird-theme--light .sendbird-color--onbackground-3--background-color {
  background-color: var(--sendbird-light-onlight-03);
}
.sendbird-theme--dark .sendbird-color--onbackground-3--background-color {
  background-color: var(--sendbird-dark-ondark-03);
}

.sendbird-theme--light .sendbird-color--onbackground-4 {
  color: var(--sendbird-light-onlight-04);
}
.sendbird-theme--dark .sendbird-color--onbackground-4 {
  color: var(--sendbird-dark-ondark-04);
}
.sendbird-theme--light .sendbird-color--onbackground-4--background-color {
  background-color: var(--sendbird-light-onlight-04);
}
.sendbird-theme--dark .sendbird-color--onbackground-4--background-color {
  background-color: var(--sendbird-dark-ondark-04);
}

.sendbird-theme--light .sendbird-color--oncontent-1 {
  color: var(--sendbird-light-ondark-01);
}
.sendbird-theme--dark .sendbird-color--oncontent-1 {
  color: var(--sendbird-dark-onlight-01);
}
.sendbird-theme--light .sendbird-color--oncontent-1--background-color {
  background-color: var(--sendbird-light-ondark-01);
}
.sendbird-theme--dark .sendbird-color--oncontent-1--background-color {
  background-color: var(--sendbird-dark-onlight-01);
}

.sendbird-theme--light .sendbird-color--primary {
  color: var(--sendbird-light-primary-300);
}
.sendbird-theme--dark .sendbird-color--primary {
  color: var(--sendbird-dark-primary-200);
}
.sendbird-theme--light .sendbird-color--primary--background-color {
  background-color: var(--sendbird-light-primary-300);
}
.sendbird-theme--dark .sendbird-color--primary--background-color {
  background-color: var(--sendbird-dark-primary-200);
}

.sendbird-theme--light .sendbird-color--error {
  color: var(--sendbird-light-error-300);
}
.sendbird-theme--dark .sendbird-color--error {
  color: var(--sendbird-dark-error-200);
}
.sendbird-theme--light .sendbird-color--error--background-color {
  background-color: var(--sendbird-light-error-300);
}
.sendbird-theme--dark .sendbird-color--error--background-color {
  background-color: var(--sendbird-dark-error-300);
}