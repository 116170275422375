.sendbird-more-members__popup-scroll {
  max-height: 420px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.sendbird--mobile-mode .sendbird-more-members__popup-scroll {
  max-height: 100%;
}
.sendbird-more-members__popup-scroll .sendbird-user-list-item__operator {
  right: 72px;
}
.sendbird-more-members__popup-scroll .sendbird-user-list-item__title {
  max-width: 240px;
}

.sendbird-channel-settings__badge {
  position: absolute;
  right: 48px;
}
.sendbird-theme--light .sendbird-channel-settings__badge {
  background-color: var(--sendbird-light-background-200);
}
.sendbird-theme--dark .sendbird-channel-settings__badge {
  background-color: var(--sendbird-dark-background-400);
}
.sendbird-theme--light .sendbird-channel-settings__badge .sendbird-label {
  color: var(--sendbird-light-onlight-02);
}
.sendbird-theme--dark .sendbird-channel-settings__badge .sendbird-label {
  color: var(--sendbird-dark-ondark-02);
}

.sendbird-channel-settings__user-panel .sendbird-channel-settings-accordion__footer {
  padding-left: 14px;
  padding-top: 14px;
}