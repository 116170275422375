.sendbird-frozen-channel-notification {
  position: relative;
  margin-top: 8px;
  margin-right: 24px;
  margin-left: 24px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.sendbird-theme--light .sendbird-frozen-channel-notification {
  background-color: var(--sendbird-light-information-100);
}
.sendbird-theme--dark .sendbird-frozen-channel-notification {
  background-color: var(--sendbird-dark-information-100);
}